<template>
  <router-view
    :url="url" 
    :urldatalog="urldatalog"
    :urlelto="urlelto"
    :urlpathgenimg="urlpathgenimg"
    :urlauge="urlauge"
    :urlqcm="urlqcm"
  />
</template>

<script>
export default {
  data: () => {
    return {
      url: 'https://testingmonitornanosense-caqa6tsjeq-et.a.run.app',
      urldatalog: 'https://testingmonitordatalog-caqa6tsjeq-et.a.run.app',
      urlelto: 'https://testingmonitorelto-caqa6tsjeq-et.a.run.app',
      urlpathgenimg: 'https://testingmonitorpathgenimg-caqa6tsjeq-et.a.run.app',
      urlauge: 'https://testingmonitorauge-caqa6tsjeq-et.a.run.app',
      urlqcm: 'https://testingmonitorqcm-caqa6tsjeq-et.a.run.app'
    }
  },
}
</script>